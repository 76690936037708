import React from 'react';
import moment from 'moment';

export default class AcceptEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreedToTerms: false,
    };
  }

  handleAgree = () => {
    this.setState({ agreedToTerms: true });
  };

  renderDay(event) {
    const startHour = moment(event.start_datetime).hour();
    return startHour < 3
      ? moment(event.start_datetime).subtract(1, 'days').format('dddd')
      : moment(event.start_datetime).format('dddd');
  }

  renderButtons() {
    return (
      <div className="d-flex justify-content-between mt-4">
        <a
          href={`/conventions/${this.props.convention.slug}/events/${this.props.event.uuid}/accept?pass=${this.props.pass}`}
          className="btn btn-success btn-lg w-45"
        >
          Accept
        </a>
        <a
          href={`/conventions/${this.props.convention.slug}/events/${this.props.event.uuid}/decline?pass=${this.props.pass}`}
          className="btn btn-danger btn-lg w-45" style={{ backgroundColor: '#dc3545', borderColor: '#dc3545' }}
        >
          Decline
        </a>
      </div>
    );
  }

  render() {
    return (
      <div className="container py-4">
        <div className="card shadow">
          <div className="card-body">
            <h1 className="card-title mb-4">
              Panel Timeslot for: <strong>{this.props.event.event_name}</strong>
            </h1>
            <div className="row">
              <div className="col-md-6">
                <h4>Panel Details</h4>
                <ul className="list-group list-group-flush mb-4">
                  <li className="list-group-item">
                    <strong>Name:</strong> {this.props.event.event_name}
                  </li>
                  <li className="list-group-item">
                    <strong>Description:</strong> {this.props.event.external_desc}
                  </li>
                  <li className="list-group-item">
                    <strong>Day:</strong> {this.renderDay(this.props.event)}
                  </li>
                  <li className="list-group-item">
                    <strong>Time:</strong>{' '}
                    {moment(this.props.event.start_datetime).format('ddd')} @{' '}
                    {moment(this.props.event.start_datetime).format('hh:mm A')} to{' '}
                    {moment(this.props.event.end_datetime).format('ddd')} @{' '}
                    {moment(this.props.event.end_datetime).format('hh:mm A')}
                  </li>
                  <li className="list-group-item">
                    <strong>Location:</strong> {this.props.event.location} - {this.props.eventroom_name}
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h4>Compensation</h4>
                <p>
                  We provide compensation for those that host panels at Holiday Matsuri. Only the main host
                  who submitted the panel will be paid for completed panels. After you complete your panel,
                  you may visit Panel HQ to receive reimbursement. Payment will be made via cash throughout
                  the weekend.
                </p>
                <p>
                  If your panel is between the hours of <strong>10:00 am to 6:00 pm</strong> at the OCCC, you will
                  be able to collect payment at Panel HQ in <strong>Room W236</strong> on the Main Convention
                  Floor.
                </p>
                <p>
                  If your panel is between the hours of <strong>7:00 pm to 1:00 am</strong> at the Hyatt, you will
                  be able to collect payment from a member of the Panels team at the Info Desk. If you miss
                  your payment window at the Hyatt, you can pick up your payment the next day at the OCCC.
                </p>
                <p>
                  Panel payments will end at <strong>6:00 pm on Sunday, December 22nd</strong>. If you have not
                  picked up your payment by this time, you will have one week from the end of the show, until{' '}
                  <strong>Monday, December 30th</strong>, to arrange for an alternate means of payment, or you
                  will forfeit your payment for your panel.
                </p>
                <p className="mb-0">Compensation is as follows:</p>
                <ul className='list-unstyled'>
                  <li>1 Hour: $20</li>
                  <li>1.5 Hours: $30</li>
                  <li>2 Hours: $40</li>
                </ul>
              </div>
            </div>
            {!this.state.agreedToTerms ? (
              <div className="mt-4">
                <button className="btn btn-primary btn-lg" onClick={this.handleAgree}>
                  I Agree to the Terms for Compensation
                </button>
              </div>
            ) : (
              this.renderButtons()
            )}
          </div>
        </div>
      </div>
    );
  }
}
